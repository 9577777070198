.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.fade-in {
	animation: load-page 0.5s linear;
}

.fade-in-2 {
	animation: load-page 0.25s linear;
}

@keyframes load-page {

	/* Define your animation keyframes here */
	/* For example: */
	0% {
		opacity: 0;
		filter: brightness(0%);
	}

	100% {
		opacity: 1;
		filter: brightness(100%);
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

/* variables globales */
:root {
	--principal: #83b600;
	--secundario: #313131;
	--fondo: #e2e2e2;
	--fondo2: #e0b7b7;
	--fondoTarjeta: #f8f8f8;
	--fondoTarjetaHv: #fcf0ba;
	--fondoMenuHeader: #eeeeee;

}

.fondo-lateralmenu{
	background-color: var(--fondoMenuHeader); 
	border-right: 1px solid #d1d1d1; /* Línea lateral */
	min-height: 90vh;
}
.back-main {
	background-image: url(./media/bg-2.png);
	background-size: 100% auto;
	/* Para cubrir el ancho completo y repetir verticalmente */
	background-repeat: repeat-y;
	/* Solo para estar seguro de que se repita verticalmente */
	min-height: 100vh;
  }

  .barra-progreso{
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 40px;
	padding: 2em;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: white;
	background-color: rgba(0, 0, 0, 0.5); /* Ajusta el valor alpha según sea necesario para controlar la opacidad */
  
  }

.logo {
	display: flex;
	justify-content: center;
	padding: 0.51em;
}

.logo-header {
	max-width: 10em;
	/* filter: drop-shadow(
		0 0 10px var(--secundario)
	); */

}
.boton-lateral{
	height: 90vh;
	display: flex;
	align-items: center;
}

.header {
	background-color: var(--fondoMenuHeader);
	min-height: 10vh;
	color: var(--secundario);
	display: flex;
	justify-content: space-around;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	align-items: center;
}

/* .App {
  background-color: var(--fondo);
  min-height: 100vh;
  background-color: var(--fondo);
  opacity: 1;
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, var(--fondo) 300px), repeating-linear-gradient(var(--fondo), var(--fondo2));
} */

.App {
	background: rgb(205, 108, 108);
	background: linear-gradient(0deg, var(--fondo2) 0%, var(--fondo) 49%, var(--fondo) 100%);
	min-height: 100vh;
	
}

.felx-left {
	display: flex;
	align-items: center;
	justify-content: start;
	/* min-height: 10vh; */
	margin: 1em;
}

.felx-betwen {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* min-height: 10vh; */
	margin: 1em;
}

.vertical-menu {
	min-height: 40vh;
	display: flex;
	align-items: center;

}

.menu-button {
	min-width: 14em !important;
	min-height: 6vh;
}

.menu-principal {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding: 40px;
	box-sizing: border-box;
	overflow: hidden;
}

.menu-card:hover {
	transform: translateY(-5px);
	/* Animacion para levantar un poco las tarjetas al pasar el raton por encima */
}

.card {
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	/* Añadir sombra por defecto */
	transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.menu-card {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	transition: all 0.3s ease;
}

.flex-container {
	display: flex;
	justify-content: center;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loading-spinner {
	width: 50px;
	height: 50px;
	border: 10px solid var(--principal);
	/* Light grey */
	border-top: 10px solid #ffffff00;
	/* Black */
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
}

.spinner {
	margin-top: 20vh;
}

.flex-center {
	display: flex;

	justify-content: center;
}

.Sin-iniciar {
	background-color: #61dafb;
}

.tarea-card {
	/* min-height: 72vh !important;
	max-height: 72vh !important; */
	background-color: var(--fondoTarjeta);
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	transition: ease-in 0.2s;
}

.fichaje-card {
	background-color: white;
	border-radius: 10px;
	/* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
	transition: ease-in 0.2s;
}

/* Estilos para pantallas de tamaño móvil */
@media (min-width: 769px) and (max-width: 1024px) {
	.tarea-card {
		height: auto;
	}
}

.tarea-card-sa {
	min-height: 50vh !important;
	max-height: 50vh !important;
	background-color: var(--fondoTarjeta);
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	transition: ease-in 0.2s;
}

/* Estilos para pantallas de tamaño móvil */
@media (min-width: 769px) and (max-width: 1024px) {
	.tarea-card-sa {
		height: auto;
	}
}

.proyecto-card {
	background-color: var(--fondoTarjeta);
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 4em;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	font-weight: 600;
	transition: ease-in 0.2s;
	display: flex;
	justify-content: space-around;

}

.proyecto-card:hover {
	background-color: var(--fondoTarjetaHv);
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 4em;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 15px 25px;
	font-weight: 600;
	transition: ease-in 0.2s;
	display: flex;
	justify-content: space-around;
}

.icon {
	color: var(--principal);
	font-weight: 600;
	transition: ease-in 0.2s;
}

.icon:hover {
	color: var(--fondo2);
	font-weight: 600;
	transition: ease-in 0.2s;
}

.ellip {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.button-default {
	font-size: 11px !important;
}

.flex-rigth {
	display: flex;
	align-self: center;
	justify-content: right;
}

.comentarios-background {
	background-color: rgb(228, 228, 228);
	border-radius: 10px;
	box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
	padding: 1em;
}


.bocadillo-chat {
	max-width: 60%;
	padding: 10px 20px;
	margin: 10px;
	border-radius: 5px;
	font-family: Arial, sans-serif;
	font-size: 14px;
	position: relative;
	display: inline-block;
	line-height: 1.4;
	background-color: #cfcfcf;
	font-weight: 600;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* Style for chat bubbles from the author */
.bocadillo-chat.autor {
	background-color: #DCF8C6;
	/* Light green background for the author */
	margin-left: auto;
	text-align: right;
}

/* Style for chat bubbles from others */
.bocadillo-chat.no-autor {
	background-color: #FFFFFF;
	/* White background for others */
	margin-right: auto;
	text-align: left;
}

.envase-chat {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin: 20px;
	box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background-color: rgb(236, 236, 236);
	color: #282c34;
}

.image-container {
	min-width: 100%;
	display: flex;
	justify-content: center;

}

.miniatura {
	display: flex;
	justify-content: center;
	max-width: 80%;
}

.confirmar-imagen {
	max-width: 75%;
	min-width: 75%;
	margin-top: 1em;
}

.contenedor-chat {
	display: flex;
	flex-direction: column;

}

.comentario-fecha {
	font-size: smaller;
	font-weight: 600;
	color: #6b6b6b;
	text-align: right;
	min-width: 100%;

}

.chat-bar {
	display: flex;
	justify-content: space-around;
	min-width: 100%;
	/* padding: 0.5em; */

}

.textfiled-chat {
	min-width: 85% !important;
}

.foto-ticket {
	min-width: 100%;

}

.ellipsis {
	/* white-space: nowrap; */
	overflow: hidden;
	text-overflow: ellipsis;
}

.descripcion-tarea {
	min-height: 9vh;
	max-height: 9vh;
}

.descripcion-titulo {
	min-height: 5vh;
	max-height: 5vh;
	overflow: hidden;
	text-overflow: ellipsis;
}

.inputDateInput {
	min-height: 2em;
	background-color: #ffffff00;
	color: #0f0f0fb0;
	/* max-width: 6em; */
	border-radius: 5px;
	font-size: 25px;
	border-color: #3c3c3b2f;
	font-family: 'Times New Roman', Times, serif;
}

.inputDateInput-empty {
	min-height: 2em;
	background-color: rgba(255, 0, 0, 0.178);
	color: #0f0f0fb0;
	/* max-width: 6em; */
	border-radius: 5px;
	font-size: 25px;
	border-color: #3c3c3b2f;
	font-family: 'Times New Roman', Times, serif;
}


.separador {
	min-width: 100% !important;
	min-height: 0vh;
	/* margin-top: 0.5em;
	margin-bottom: 0.5em; */
	border: solid 0.1em #e0b7b7;
}

.pointer {
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.textfieldBuscador {
	margin-top: 1.4em !important;
}

.returnButton {
	margin: 2em 2em 0em 2em !important;
	padding: 1em;
}

.loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;
	/* Ajusta según lo necesario */
}

#primary_color {
	border-radius: 50%;
	height: 60px;
	width: 60px;
	border: none;
	outline: none;
	-webkit-appearance: none;
	cursor: pointer;
}

#primary_color::-webkit-color-swatch-wrapper {
	padding: 0;
}

#primary_color::-webkit-color-swatch {
	border: none;
	border-radius: 50%;
}

.miniatura-perfil-form {
	border-radius: 50%;
	width: 65px;
	height: 65px;
	object-fit: cover;
}

.css-1wjk1gj-MuiTable-root {
	min-width: unset !important
}

.barra-carga{
	min-width: 100% !important;
}
.footer{
	backgroundColor: '#2c3e50';
    color: '#ecf0f1';
    textAlign: center;
    padding: -10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 00;
}

.body {
	margin: 0 !important;
	min-height: 40em;
	background-color: rgb(32, 31, 31);
  
  }
  
  .code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
  }
  
  .post-view-item {
	margin: 2em;
	background-color: rgb(44, 32, 16);
	border-radius: 5px;
	min-width: 20px;
  }
  
  .post-view-form {
	max-width: 90%;
	margin: 2em;
	background-color: rgb(44, 32, 16);
	border-radius: 5px;
	min-width: 20px;
  }
  
  .home-view {
	background-color: rgb(32, 31, 31);
  }
  
  .card-content {
	background-color: rgb(99, 99, 99);
	color: white;
  }
  
  .menu-bar {
	background-color: black;
  }
  
  .logo {
	margin: 0 1em;
	cursor: pointer;
  }
  
  .menu-bar-container {
	justify-content: space-between;
	align-items: center;
  }
  
  .back-ground-coontainer {
	max-width: 100%;
	min-height: 60em;
	margin: auto;
  }
  
  .home-bg {
	min-width: 100%;
	min-height: 60em;
	background-color: rgb(32, 31, 31);
  }
  
  .post-projects {
	display: flex;
	align-items: center;
  }
  
  .home-view-posts {
	display: flex;
	/* justify-content: start; */
	justify-content: center;
	margin: 2em;
	max-width: 100%;
	flex-wrap: wrap;
  }
  
  .post-view-item {
	margin: 1;
  
  }
  
  .welcome-logo {
	max-width: 60%;
	text-align: center;
	margin: 10%;
  }
  
  .to-post-view {
	text-align: center;
	color: white;
	font-weight: 500;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	font-style: oblique;
	padding: 0 2em;
	background-color: gray;
	border-radius: 5px;
  }
  
  .person-icon {
	color: white;
	text-align: center;
  }
  
  .login-content {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
  }
  
  .text-field {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgb(168, 168, 168);
	padding: 20px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	max-width: 400px;
	width: 100%;
	margin: 0px 20px;
  }
  
  .add-post-container {
	margin: 2em;
  }
  
  .adds-view {
	min-width: 100%;
	min-height: 60em;
	background-color: rgb(99, 99, 99);
  
  }
  
  .css-1xzqp72-MuiPaper-root-MuiCard-root {
	background-color: rgb(99, 99, 99) !important;
	color: white !important;
  }
  
  .menu-bar-container {
	color: white;
	align-items: center;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  
  .logo-button {
	display: flex;
	align-items: center;
  }
  
  .posts-button {
	display: flex;
	align-items: center;
  }
  
  .log-button {
	cursor: pointer;
	color: rgb(255, 255, 255);
  }
  
  .add-button {
	cursor: pointer;
	color: rgb(255, 255, 255);
  }
  
  .post-button {
	cursor: pointer;
	color: rgb(255, 255, 255);
  }
  
  .log-button:hover {
	cursor: pointer;
	color: rgb(172, 3, 172);
	transition: 1s;
  }
  
  .add-button:hover {
	cursor: pointer;
	color: rgb(172, 3, 172);
	transition: 1s;
  
  }
  
  .post-button:hover {
	cursor: pointer;
	color: rgb(172, 3, 172);
	transition: 1s;
  
  }
  
  .formulario {
	text-align: center;
  }
  
  .components-menu-container {
	display: flex;
	justify-content: center;
	flex-flow: wrap;
  }
  
  .to-post-view {
	display: inline-block;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	color: rgb(0, 0, 0);
	min-height: 14em;
	text-align: center;
	font-weight: 100;
	font-size: 5em;
	margin: 1em 0;
	cursor: pointer;
  }
  
  .to-post-view:hover {
	display: inline-block;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	color: rgb(0, 0, 0);
	min-height: 14em;
	border-color: blueviolet;
	text-align: center;
	font-weight: 100;
	font-size: 5em;
	margin: 1em 0;
	cursor: pointer;
  }
  
  .socioCard {
	background-color: rgb(230, 230, 230);
	width: 20em;
	border-radius: 10px;
	text-align: center;
	padding: 1em;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  
  .fincaCard {
	background-color: rgb(230, 230, 230);
	width: 20em;
	border-radius: 10px;
	text-align: center;
	padding: 1em;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  
  .preCard {
	background-color: rgb(230, 230, 230);
	width: 12em;
	border-radius: 10px;
	text-align: center;
	padding: 0.5em;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  
  .uploadRow {
	min-width: 50%;
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	flex-flow: wrap;
	gap: 10px 20px;
	background-color: rgb(219, 219, 219);
	padding: 0.5em;
	border-radius: 5px;
	margin: 0.2em;
	box-shadow: rgb(185, 185, 185) 3px 3px 6px 0px inset, rgba(165, 165, 165, 0.5) -3px -3px 6px 1px inset;
  }
  
  .addRow {
	min-width: 50%;
	display: flex;
	justify-content: space-evenly;
	flex-flow: wrap;
	gap: 10px 20px;
	background-color: antiquewhite;
	padding: 0.5em;
	border-radius: 5px;
	margin: 0.2em;
	box-shadow: rgb(185, 185, 185) 3px 3px 6px 0px inset, rgba(165, 165, 165, 0.5) -3px -3px 6px 1px inset;
  }
  
  .logo-header {
	max-width: 20em;
  }
  
  .cardsContainer {
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	gap: 10px 20px;
  }
  
  .card {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
  }
  
  .card-element {
	background-color: #dbdbdb;
	padding: 0.5em;
	margin: 0.2em;
	border-radius: 5px;
	min-width: 8.5em;
  }
  
  .card-title {
	background-color: dimgrey;
	width: 100%;
	min-height: 4vh;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 20px;
	font-weight: 600;
	border-radius: 5px;
  
  }
  
  .VariedadCard {
	background-color: red;
  }
  
  .header-logo {
	background-color: #eeeeee;
	display: flex;
	justify-content: space-around;
	padding: 1em;
	margin: 0 0 1em 0;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
  }
  
  .option-container {
	max-width: 90%;
	min-width: 90%;
	justify-content: center;
	align-items: start;
	display: flex;
	margin-bottom: 4em;
  }
  
  .create-person-button {
	padding: 0.5em 1em;
	transition-duration: 500ms;
	background-color: #83b600;
	display: flex;
	align-items: center;
	color: white;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	max-width: 12em;
	border-radius: 20px;
	cursor: pointer;
	margin: 1em
  }
  
  .create-person-button:hover {
	padding: 0.5em 1em;
	transition-duration: 500ms;
	background-color: #0f5b3f;
	display: flex;
	align-items: center;
	color: white;
	font-size: 16px;
	font-weight: 600;
	max-width: 12em;
	border-radius: 20px;
	cursor: pointer;
	margin: 1em
  }
  
  .flex-container {
	display: flex;
	justify-content: center;
  }
  
  .header {
	font-size: 20px;
	font-weight: 700;
	background-color: #eeeeee;
  
  }
  
  .sub-header {
	font-size: 20px;
	font-weight: 700;
	min-width: 100%;
	max-width: 100%;
	max-height: 4em;
	text-align: center;
	margin-bottom: 2em;
  }
  
  .delete-person-button {
	padding: 0.5em 0.5em;
	transition-duration: 500ms;
	background-color: #83b600;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	max-width: 4.5em;
	border-radius: 20px;
	cursor: pointer;
	margin: 1em;
	user-select: none;
  }
  
  .delete-person-button:hover {
	padding: 0.5em 0.5em;
	transition-duration: 500ms;
	background-color: #0f5b3f;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 16px;
	font-weight: 600;
	max-width: 4.5em;
	border-radius: 20px;
	cursor: pointer;
	margin: 1em
  }
  
  .info-plantaciones-button {
	padding: 0.5em 0.5em;
	transition-duration: 500ms;
	background-color: #83b600;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	max-width: 7.5em;
	border-radius: 20px;
	cursor: pointer;
	margin: 1em
  }
  
  .info-plantaciones-button:hover {
	padding: 0.5em 0.5em;
	transition-duration: 500ms;
	background-color: #0f5b3f;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 16px;
	font-weight: 600;
	max-width: 7.5em;
	border-radius: 20px;
	cursor: pointer;
	margin: 1em
  }
  
  .button {
	padding: 1em 2em;
	transition-duration: 500ms;
	background-color: #83b600;
	display: flex;
	justify-content: center;
	color: white;
	text-align: center;
	align-items: center;
	font-size: 18px;
	font-weight: 600;
	border-radius: 10px;
	cursor: pointer;
	margin: 1em;
	max-height: 1em;
	user-select: none;
  }
  
  .button:hover {
	padding: 1em 2em;
	transition-duration: 500ms;
	background-color: #0f5b3f;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 18px;
	font-weight: 600;
	border-radius: 10px;
	cursor: pointer;
	max-height: 1em;
  
	margin: 1em
  }
  
  .flex {
	display: flex;
	flex-direction: column;
  }
  
  .header-space {
	min-height: 10em;
	margin: 10em
  }
  
  .back {
	max-width: 6em;
	align-items: center;
	font-size: 18px;
  }
  
  .loading-icon {
	color: #83b600;
  
  }
  
  @keyframes spinner {
	0% {
	  transform: rotate(0deg);
	}
  
	100% {
	  transform: rotate(360deg);
	}
  }
  
  .loading-spinner {
	width: 50px;
	height: 50px;
	border: 10px solid #83b600;
	/* Light grey */
	border-top: 10px solid #0f5b3f;
	/* Black */
  /* #83b600xx; */
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
  }
  
  .title {
	font-size: 65px;
	font-weight: 600;
	background-color: #eeeeee;
	color: rgb(114, 114, 114);
	width: 95%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	padding: 0.01em 0.5em;
	margin: 0.1em;
	cursor: default;
  }
  
  .button-main {
	padding: 1em 10em;
	transition-duration: 500ms;
	background-color: #83b600;
	display: flex;
	justify-content: center;
	color: white;
	text-align: center;
	align-items: center;
	font-size: 32px;
	font-weight: 600;
	border-radius: 6px;
	cursor: pointer;
	margin: 0.1em;
	max-height: 1em;
	box-shadow: 0px 0px 10px rgba(85, 85, 85, 0.5);
  
  }
  
  .button-main:hover {
	padding: 1em 10em;
	transition-duration: 500ms;
	background-color: #0f5b3f;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 32px;
	font-weight: 600;
	border-radius: 6px;
	cursor: pointer;
	margin: 0.1em;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  
  
  }
  
  .upload-box {
	padding: 2em 1em;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: antiquewhite;
	border-radius: 10px;
	font-size: 20px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	margin-bottom: 1em;
  }
  
  .back-main {
	background-image: url(./media/bg-2.png);
	background-size: 100% auto;
	/* Para cubrir el ancho completo y repetir verticalmente */
	background-repeat: repeat-y;
	/* Solo para estar seguro de que se repita verticalmente */
	min-height: 100vh;
  }
  
  .variedades-container {
	background-color: #dbdbdb59;
	backdrop-filter: blur(10px);
	min-width: 90%;
	max-width: 90%;
	border-radius: 10px;
	min-height: 10vh;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	flex-flow: wrap;
  }
  .variedades-header{
	min-width: 100%;
	display: flex;
	justify-content: space-between;
  }
  
  .flex-center-100{
	display: flex;
	width: 100%;
	justify-content: center;
	margin: 1em;
  }
  .login-card{
	  display: flex;
	  justify-content: center;
	  flex-wrap: wrap;
	  align-items: start;
	  max-width: 22em;
	  background-color: #eeeeee;
	  margin: 8em;
	  min-height: 5vh;
	  font-size: 20px;
	  font-weight: 600;
	  border-radius: 20px;
	  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	}
	.login-element{
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  padding: 1em;
	}
	.login-icon{
	  min-width: 100%;
	  max-height: 10vh;
	  text-align: center;
	  padding-top: 1em;
	  color: #83b600;;
	  
	}
	.return-button{
	  background-color: #83b600;
	  transition-duration: 500ms;
	  color: aliceblue;
	  min-height: 3em;
	  max-height: 3em;
	  min-width: 8em;
	  max-width: 10em;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  border-radius: 5px;
	  padding: 0.1em;
	  font-size: 18px;
	  font-weight: 600;
	  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	  cursor: pointer;
	  margin:0 1.5em 0.8em ;
	}
	.return-button:hover{
	  background-color:#0f5b3f;;
	  transition-duration: 500ms;
	  color: aliceblue;
	  min-height: 3em;
	  max-height: 3em;
	  min-width: 8em;
	  max-width: 10em;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  border-radius: 5px;
	  padding: 0.1em;
	  font-size: 18px;
	  font-weight: 600;
	  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	  cursor: pointer;
	  margin:0 1.5em 0.8em ;
	}
	.main-menu-button{
	  background-color: #83b600;
	  transition-duration: 500ms;
	  color: aliceblue;
	  min-height: 4em;
	  max-height: 4em;
	  min-width: 18em;
	  max-width: 20em;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  border-radius: 5px;
	  padding: 0.1em;
	  font-size: 18px;
	  font-weight: 600;
	  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	  cursor: pointer;
	}
	.main-menu-button:hover{
	  background-color: #0f5b3f;
	  transition-duration: 500ms;
	  color: aliceblue;
	  min-height: 4em;
	  max-height: 4em;
	  min-width: 18em;
	  max-width: 20em;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  border-radius: 5px;
	  padding: 0.1em;
	  font-size: 18px;
	  font-weight: 600;
	  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	  cursor: pointer;
	}
	.flip{
	  display: flex;
	  align-items: center;
	  transform: rotate(180deg);
	}
  /* ////////////////////////////////////////////////////////////////////////////////////////////// */
  /* CSS de utils */
  
  .notification-container {
	max-width: 98%;
	min-width: 98%;
	display: flex;
	justify-content: right;
	bottom: 20px;
	right: 20px;
	z-index: 9999;
  }
  .barra-progreso{
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 40px;
	padding: 2em;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: white;
	background-color: rgba(0, 0, 0, 0.5); /* Ajusta el valor alpha según sea necesario para controlar la opacidad */
  
  }
  .barra{
	background-color:#0f5b3f;
	max-width: 40%;
	min-width: 40%;
  }
  
  .user-logged{
	  max-width: 10em;
	  min-width: 10em;
	  min-height: 5vh;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  font-size: 18px;
	  font-weight: 600;
	  color: #3c3c3b;
  }
  .logo-header{
	max-width: 20em;
  }
  .notification-container {
	position: fixed;
	bottom: 20px;
	right: 20px;
	background-color: #fff;
	border: 1px solid #ccc;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	padding: 10px 20px;
	border-radius: 5px;
	z-index: 9999;
  }
  
  .notification {
	font-size: 16px;
	color: #333;
  }
  
  .fondo-lateralmenu{
	  background-color: var(--fondoMenuHeader); 
	  border-right: 1px solid #d1d1d1; /* Línea lateral */
	  min-height: 90vh;
  }
  .boton-lateral{
	  height: 90vh;
	  display: flex;
	  align-items: center;
  }